<template>
    <div>
        <el-alert
                v-if="resumeListAsMe.length===0"
                style="width: 60vw;margin: 20px auto; "
                title="请创建简历"
                description="创建简历后即可投递简历，更能获得工作机会哦"
                type="info"
                :closable="true"
                :show-icon="true"
                :center="true"
        >
        </el-alert>
        <div class="u-resume user-content flex">
            <div class="u-resume-wrapper">
                <div class="smrz u-no-resume" v-if="showsm">

                    <div style="margin-bottom:20px; font-size:14px; color:f60;"><router-link to="/user/realname">提示：请先进行实名认证，点击去认证！</router-link></div>

                </div>
                <div class="u-resume-list" v-if="status">
                    <el-table
                            v-if="resumeListAsMe.length>0"
                            :data="resumeListAsMe"
                            style="width: 100%">
                        <el-table-column
                                prop="resumeName"
                                align="center"
                                label="简历名称">
                        </el-table-column>
                        <el-table-column
                                prop="isDefault"
                                align="center"
                                label="默认">
                                <template slot-scope="scope">
                                    <el-tag type="success" v-if="scope.row.isDefault==1">是</el-tag>
                                    <el-tag type="info" v-if="scope.row.isDefault==0">否</el-tag>
                                </template>
                        </el-table-column>
                        <el-table-column
                                prop="updateTime"
                                
                                align="center"
                                label="更新时间">
                            <template slot-scope="scope">
                                <div>{{scope.row.editTime.split(' ')[0]}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                align="center"
                                width="350px"
                                label="操作">
                            <template slot-scope="scope">
                                <el-button-group size="mini">
                                    <el-button type="primary" :disabled="scope.row.isDefault==1?true:false" plain size="mini" @click="setDefaultResume(scope.row.id)">
                                        设为默认
                                    </el-button>
                                    <el-button type="success" plain size="mini" @click="openUserResume(scope.row.id)">
                                        预览简历
                                    </el-button>
                                    <el-button type="warning" plain size="mini" @click="editResume(scope.row.id)">修改简历
                                    </el-button>
                                    <el-button type="danger" plain size="mini" @click="deleteResume(scope.row.id)">
                                        删除简历
                                    </el-button>
                                </el-button-group>
                                <!-- <el-button type="primary" plain size="mini">设为默认</el-button>
                                <el-button type="success" plain size="mini" @click="openUserResume">预览简历</el-button>
                                <el-button type="warning" plain size="mini">修改简历</el-button>
                                <el-button type="danger" plain size="mini">删除简历</el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="add-resume flex">
                        <div class="a-resume-btn" @click="routeToCreatResume">添加简历</div>
                    </div>
                </div>
                <div class="u-no-resume flex"  v-if="showsm==false && status==false">
                    <div class="no-resume-img">
                        <img src="../../../assets/img/company/empty.png" alt="">
                    </div>
                    <div class="no-resume-text">暂无简历</div>
                    <div class="choose-upload" @click="routeToCreatResume">创建简历</div>
                </div>
            </div>
            <div class="sider-resume">
                <upload-pdf></upload-pdf>
            </div>
            <show-user-resume :showResume="showResumeStatus" :resumeInfo="resumeInfo"
                              @closeUserResume="closeUserResume"></show-user-resume>

        </div>

    </div>
</template>
<script>
    import UploadPdf from '../component/UploadPdf'
    import ShowUserResume from '../component/ShowUserResume'
    import userRequest from '../../../api/user'

    export default {
        components: {
            UploadPdf,    /* 上传简历 */
            ShowUserResume,
           
        },
        created(){
            
            this.user=JSON.parse(localStorage.getItem("userInfo"))
            console.log(this.user.age)
        },
        mounted() {
            this.getUserResumeList()

        },
        data() {
            return {
                showResumeStatus: false,
                resumeListAsMe: [],  // 简历列表
                resumeInfo: {},
                status: true,
                user:null,
                showsm:false,
                personid:"",
                realname:"",
                sm:null,
                resumeStr:null,//在线简历
                // resumeListAsMe: []
            }
        },
        methods: {
            putrz(){
                if(this.personid==""){
                    this.$message.error("请输入身份证号码！")
                    return
                }
                userRequest.setRealName({idNumber:this.personid,userName:this.realname}).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        userRequest.getUserInfo({}).then(ress=>{
                            console.log(ress)
                            localStorage.setItem("userInfo",JSON.stringify(ress.data))
                            this.$message.success("认证成功！")
                            this.user=ress.data
                            this.showsm=false
                        })
                        
                    }else{
                        this.$message.error(res.message)
                    }
                }).catch(err=>{
                    this.$message.error(err)
                })
            },
            openUserResume(id) {
                this.getResumeInfo(id)
            },
            /**
             * resumeId
             * */
            deleteResume(id) {
                this.$confirm('即将删除当前简历，是否继续？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // console.log("删除的简历的id",id)
                    userRequest.deleteResume({resumeId: id}).then(res => {
                        if (res.code === 200) {
                            let cIndex = this.resumeListAsMe.findIndex(res => {
                                if (res.id === id) {
                                    return true;
                                }
                            })
                            this.resumeListAsMe.splice(cIndex, 1)

                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            // console.log("简历列表",this.resumeListAsMe)
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
            },
            editResume(id) {
                this.$router.push({
                    path: '/user/creatResume',
                    query: {
                        resumeId: id
                    }
                })
            },
            /**
             * 将当前的简历设置为默认简历
             * */
            setDefaultResume(id) {
                userRequest.setDefaultResume({resumeId: id}).then(res => {
                    if (res.code === 200) {
                        // console.log("设置默认简历的返回值", res)
                        this.$message({
                            type: 'success',
                            message: '设置成功'
                        });
                        this.getUserResumeList()
                    }
                })
            },
            closeUserResume() {
                this.showResumeStatus = false
            },
            getUserResumeList() {
                userRequest.getUserResumeList().then((res) => {
                    if (res.code === 200) {
                        this.resumeListAsMe = res.data.resumeDOList
                        if (this.resumeListAsMe.length === 0) {
                            this.status = false
                        }
                    }
                }).catch((err) => {
                    this.status = false
                })
            },
            routeToCreatResume() {
                if(this.user.birthday==null || this.user.birthday=='1980-01-01'){
                    this.showsm=true
                    return
                }
                if(this.resumeListAsMe.length>5){
                    this.$message.info("至多可创建5份简历")
                    return;
                }
                this.$router.push({
                    path: '/user/creatResume'
                })
            },
            getResumeInfo(id) {
                userRequest.getResumeInfo({resumeId: id}).then((res) => {
                    if (res.code === 200) {
                        this.resumeInfo = res.data
                        setTimeout(() => {
                            this.showResumeStatus = true
                        }, 300)
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>

    .el-button {
        margin-left: 0px;
        margin-bottom: 10px;
    }

    @import "../../../assets/css/global.scss";
    .u-resume {
        min-height: 600px;
        margin-bottom: 100px;
        margin-top: 40px;
    }

    .u-resume-wrapper {
        position: relative;
        box-sizing: border-box;
        margin-top: 20px;
        min-height: 500px;
        background: #fff;
        width: 884px;
        padding: 20px;

        .u-resume-list {
            // box-sizing: border-box;
            // margin-top: 20px;
            // min-height: 500px;
            // background: #fff;
            // width: 884px;
            // padding: 20px;
            .default {
                color: $green;
                cursor: pointer;
                user-select: none;

                &:hover {
                    font-weight: 700;
                }
            }

            .delresume {
                margin-left: 8px;
                color: $danger;
                cursor: pointer;
                user-select: none;

                &:hover {
                    font-weight: 700;
                }
            }

            .add-resume {
                justify-content: flex-end;
                margin-top: 10px;
                cursor: pointer;

                .a-resume-btn {
                    display: inline-block;
                    width: 100px;
                    height: 40px;
                    line-height: 40px;
                    margin-right: 25px;
                    border-radius: 3px;
                    text-align: center;
                    background: #f6f6f6;
                    border: 1px solid #eee;

                    &:hover {
                        background: #eee;
                    }
                }
            }
        }

        .u-no-resume {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .no-resume-img {

            }

            .no-resume-text {
                margin-top: 10px;
                color: #999;
            }

            .choose-upload {
                cursor: pointer;
                margin-top: 30px;
                width: 300px;
                height: 46px;
                line-height: 46px;
                background: $main;
                text-align: center;
                color: #fff;
                border-radius: 6px;

                &:hover {
                    background: $hover;
                }
            }
        }
    }

    .sider-resume {
        margin-top: 20px;
        box-sizing: border-box;
        width: 300px;
        padding: 20px;
        background: #fff;
        margin-left: 14px;
        // .u-resume-title{
        //         font-size: 18px;
        //         line-height: 18px;
        //         color: #333;
        //         font-weight: 500;
        //         margin-bottom: 20px;
        // }
        // .upload-resume-warpper{
        //     .u-has-resume{
        //         padding: 10px;
        //         align-items: center;
        //         margin-bottom: 27px;
        //         .doc-pdf{
        //             width: 35px;
        //             height: 35px;
        //             padding: 0 13px;
        //             img{
        //                 width: 35px;
        //                 height: 35px;
        //             }
        //         }
        //         .doc-pdf-name{
        //             line-height: 18px;
        //             color: #333;
        //         }
        //     }
        //     .u-empty-resume{
        //         margin-bottom: 27px;
        //         font-size: 14px;
        //         color: #666;
        //         letter-spacing: 0;
        //         line-height: 26px;
        //     }
        // }
        // .u-upload__button{
        //     cursor: pointer;
        //     width: 100%;
        //     height: 36px;
        //     line-height: 36px;
        //     text-align: center;
        //     background: $main;
        //     border-radius: 20px;
        //     font-size: 14px;
        //     color: #fff;
        //     &:hover{
        //         background: $hover;
        //     }
        // }
        // .other-lable{
        //     margin-top: 30px;
        //     justify-content: center;
        //     .o-lable-item{
        //         cursor: pointer;
        //         flex: 1;
        //         flex-direction: column;
        //         justify-content: center;
        //         align-items: center;
        //         &:hover{
        //             color: $main;
        //         }
        //         .o-lable-img{
        //             width: 36px;
        //             height: 36px; 
        //             margin-bottom: 10px;
        //             img{
        //                 width: 36px;
        //                 height: 36px; 
        //             }
        //         }
        //     }
        // }
    }
</style>